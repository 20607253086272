import { sprintf } from 'sprintf-js';

export function getCookie(name: string): string|null {
    // Escape [] characters, so we can specify cookie name like "test[key]"
    name = name.replace(/[[\]\\]/g, '\\$&');
    const keyValue = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');

    return keyValue ? keyValue[2] : null;
}

interface CookieAttributes {
    path?: string;
    domain?: string;
    expires?: number | Date;
    partitioned?: boolean
    secure?: boolean;
    SameSite?: string;
}

type CookieArg = number | CookieAttributes;

export function setCookie(name: string, value: string | number, attributes?: CookieArg): void {
    const cookie: Record<string, string | boolean | Date> = {
        [name]:    encodeURIComponent(value),
        // Expires in 1 year by default
        expires: new Date(Date.now() + (1000 * 60 * 60 * 24 * 365)),
        path:    '/',
        secure:  true,
    };

    // Support passing just expiration in seconds
    if (typeof attributes === 'number') {
        cookie.expires = new Date(Date.now() + attributes * 1000);
    } else if (attributes) {
        // Parse other cookie options
        Object.entries(attributes).forEach(([name, value]) => {
            if (name === 'expires' && typeof value === 'number') {
                cookie[name] = new Date(Date.now() + value * 1000);
            } else {
                cookie[name] = value as string | boolean | Date;
            }
        });
    }

    const attrs: string[] = [];

    for (const name in cookie) {
        const val = cookie[name];
        if (typeof val === 'boolean') {
            val && attrs.push(name);
        } else if (val instanceof Date) {
            attrs.push(sprintf('%s=%s', name, val.toUTCString()));
        } else {
            attrs.push(sprintf('%s=%s', name, (name === 'path' || name === 'domain') ? val : encodeURIComponent(val)));
        }
    }

    document.cookie = attrs.join('; ');
}

// @ts-ignore
window.getCookie = getCookie;
// @ts-ignore
window.setCookie = setCookie;

declare global {
    interface Window {
        getCookie: typeof getCookie;
        setCookie: typeof setCookie;
    }
}
